/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"

import 'normalize.css'
import './index.css'
import '../../styles/index.css'
import SEO from '../../components/seo'
import PageTransition from 'gatsby-plugin-page-transitions'

import config from '../../config'

const Layout = ({ title, children }) => (
  <>
    <Header />
    <div>
      <main>{children}</main>
      <Footer />
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
