const localeData = [
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/fr'),
]

const languages = [
  { value: 'en', text: 'EN' },
  { value: 'fr', text: 'FR' },
]

module.exports = {
  localeData,
  languages,
  defaultLanguage: languages[0]
};