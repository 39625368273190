import React from 'react'
import { Link } from '../../i18n'
import { FormattedMessage } from 'react-intl'

import './index.css'

const Menu = ({ context }) => {
  const locale = context ? context.data.locale : null
  return (
    <nav>
      { /* <Link to="/" locale={locale} className="underline"><FormattedMessage id="menu.home" /></Link> */ }
      <Link to="/services" locale={locale} className="underline"><FormattedMessage id="menu.services" /></Link>
      <Link to="/designers" locale={locale} className="underline"><FormattedMessage id="menu.designers" /></Link>
      <Link to="/contact" locale={locale} className="underline"><FormattedMessage id="menu.contact" /></Link>
    </nav>
  )
}

export default Menu