import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import GatsbyImage from "gatsby-image"

import './index.css'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(height: 60) {
              src
            }
          }
        }
        site {
          siteMetadata {
            author
          }
        }
      }
    `}
    render={data => (
      <footer>
        <img src={data.logo.childImageSharp.fixed.src} />
        <div>
          © {new Date().getFullYear()}, <FormattedMessage id="footer.madeBy" />
          {` `}
          <a href="https://www.ulydev.com">{data.site.siteMetadata.author}</a>
        </div>
      </footer>
    )}
  />
)

export default Footer
