import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { withIntl } from "../i18n"
import { FormattedMessage } from "react-intl"
import Html from '../components/Html'
import SEO from '../components/seo'

import config from '../config'

const Presentation = ({ data }) => (
  <>
    <h1 className="uppercase">{ data.title } </h1>
    <Html html={data.content.childContentfulRichText.html} />
  </>
)

const LogoWithText = ({ image }) => (
  <>
    <a href={config.instagramLink} target="_blank">
      <a className="inline-block underline" style={{ textTransform: "uppercase", letterSpacing: "0.05rem" }}><FormattedMessage id="followUsOnInstagram" /></a>
      &nbsp;
      <img src={image.childImageSharp.fixed.src} alt="" id="follow-us-logo" className="vertical-aligned"/>
    </a>
  </>
)

const ServicesPage = ({ data }) => (
  <Layout>
    <SEO title={ data.servicesPage.title } />
    <div className="centered side-padded vertical-padded text-justified content">
      <Presentation data={data.showroomPage} />
      <LogoWithText image={data.instagramLogo} />
      <h1 className="uppercase" style={{ marginTop: "3rem" }}>{ data.servicesPage.title }</h1>
      <Html html={data.servicesPage.content.childContentfulRichText.html} />
    </div>
  </Layout>
)

export const query = graphql`
  query ($locale: String!) {
    servicesPage: contentfulPage (node_locale: { eq: $locale }, name: { eq: "Services" }) {
      name
      title
      content {
        childContentfulRichText {
          html
        }
      }
      node_locale
    }
    allContentfulDesigner (
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [name] }
    ) {
      edges {
        node {
          id
          contentful_id
          node_locale
          name
          content {
            childContentfulRichText {
              html
            }
          }
          thumbnail {
            fixed(width: 800, height: 1000) {
              src
            }
          }
        }
      }
    }
    showroomPage: contentfulPage (node_locale: { eq: $locale }, name: { eq: "Showroom" }) {
      name
      title
      content {
          childContentfulRichText {
              html
          }
      }
      node_locale
    }
    instagramLogo: file(relativePath: { eq: "instagram_logo.png" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          src
        }
      }
    }
  }
`

export default withIntl(ServicesPage)
