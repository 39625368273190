import React, { Component } from "react"
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
import { Link } from '../../i18n'
import Menu from '../Menu'
import GatsbyImage from 'gatsby-image'

import './index.css'

import LanguageSelect from '../LanguageSelect'

class Header extends Component {

  static contextTypes = {
    language: PropTypes.object,
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            logo: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                fluid(maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <header>
            <div>
              <div id="logo">
                <Link to="/">
                  <div>
                    <GatsbyImage fluid={data.logo.childImageSharp.fluid} />
                  </div>
                </Link>
              </div>
              <LanguageSelect />
            </div>
            <Menu />
          </header>
        )}
      />
    )
  }
}

export default Header
