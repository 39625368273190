module.exports = {
    footer: {
        madeBy: "fait par",
    },
    menu: {
        services: "Services",
        designers: "Designers",
        contact: "Contact",
    },
    followUsOnInstagram: "Instagram",
}