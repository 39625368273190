import React, { Component } from 'react'
import { navigate, withPrefix } from 'gatsby'
import PropTypes from 'prop-types';
import "./index.css"

class LanguageSelect extends Component {
  static contextTypes = {
    language: PropTypes.object,
  }

  state = {
    locale: ''
  }

  componentDidMount() {
    const { language } = this.context
    this.setState({
      locale: language.locale || language.detected,
    })
  }

  newPath = locale => {
    const { originalPath } = this.context.language
    return withPrefix(`/${locale}${originalPath}`)
  }

  setLocale = locale => {
    const { language } = this.context
    const { originalPath } = language

    if (locale === this.state.locale) return

    this.setState({ locale }, () => {
      localStorage.setItem('language', locale)
      navigate(this.newPath(locale))
    })
  }

  render() {
    const { language } = this.context
    const { languages, locale } = language

    if (!locale) return null

    return locale ? (
      <div id="locale-selection">
        { languages.map(({ value, text }, i) => (
          <React.Fragment key={i}>
            <span className="separator">{ (i > 0) ? ' / ' : null }</span>
            <a className={value === locale ? "selected" : undefined} key={value} onClick={() => this.setLocale(value)}>
              { text }
            </a>
          </React.Fragment>
        )) }
      </div>
    ) : null
  }
}

export default LanguageSelect