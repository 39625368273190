import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider, addLocaleData } from 'react-intl'
import { localeData, defaultLanguage } from './locales'
import flatten from 'flat'

addLocaleData(localeData)

export default ComposedComponent => {
  class withIntl extends Component {
    static childContextTypes = {
      language: PropTypes.object
    }

    constructor(props) {
      super()

      const { pathContext: pageContext } = props
      const { locale, languages, originalPath } = pageContext

      this.state = {
        language: {
          locale,
          languages,
          originalPath
        },
      }
    }

    getChildContext() {
      const { language } = this.state
      return { language }
    }

    render() {
      const { language } = this.state
      const locale = language.locale || defaultLanguage.value
      const messages = require(`./locales/${locale}.js`)

      return (
        <IntlProvider locale={locale} messages={flatten(messages)}>
          <ComposedComponent { ...this.props } />
        </IntlProvider>
      )
    }
  }

  return withIntl
}